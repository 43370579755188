<template>
	<div class="cbox">
		<div class="headbar">

			<div style="padding-top: 14px;position:relative">

				<div style=" width: 200px;  margin-top:-10px;">
					<div style="width:40px;height:40px;background-color: #ebf5ff; border-radius: 50%;float: left;">
						<img :src="'/img/page.png'" style="width:60%;height:60%;margin-left: 20%;margin-top:20%" />
					</div>
					<div style="margin-left: 50px; color:#535353; font-size:20px !important">电话调查</div>
				</div>

				<div style="position: absolute; right: 0; top:0">
					<el-button type="infor" @click="donwAll" size="small" icon="el-icon-download">下载全部文件</el-button>
				</div>

			</div>
		</div>
		<div class="bcontent">

			<el-row :gutter="20">
				<div style="height:82vh; width:250px; padding-left:10px; overflow:auto; float: left;">
					<!-- <div :class="['school', schoolId == 0 ? 'ckd' : '']" @click="setSchool(0)">全部学校</div> -->
					<div :class="['school', schoolId == item.id ? 'ckd' : '']" @click="setSchool(item.id)"
						v-for="(item, idx) in SchoolList" :key="idx">{{ item.platform }}<i v-if="item.hasData"
							class="el-icon-star-on" style="color:#547df2"></i></div>
				</div>

				<div style="height:82vh;overflow:auto; margin-left: 280px;">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane label="就业统计图" name="first"></el-tab-pane>
						<el-tab-pane label="就业统计表" name="second"></el-tab-pane>
						<el-tab-pane label="毕业生分类表" name="third"></el-tab-pane>
						<el-tab-pane label="就业名册" name="forth"></el-tab-pane>

					</el-tabs>

					<diaochaChart :tPmid="schoolId" v-if="activeName == 'first' && schoolId > 0"></diaochaChart>

					<div v-if="tjData && activeName == 'second'">

						<div style="text-align:right">
							<a :href="tjData.file" target="_blank"
								style="margin-right:20px;cursor:pointer;color:#409eff">毕业生就业统计表下载</a>

						</div>
						<div id="tableContent" style="min-width:1000px; margin:0 auto;" v-html="tjData.html_content">
						</div>
					</div>



					<diaochaFenlei :tPmid="schoolId" v-if="activeName == 'third' && schoolId > 0"></diaochaFenlei>
					<jymingce :tpmid="schoolId" v-if="activeName == 'forth' && schoolId > 0"></jymingce>

				</div>


			</el-row>
		</div>

	</div>
</template>

<script>
import diaochaChart from './com/diaochaChart.vue'
import diaochaFenlei from './com/diaochaFenlei.vue'
import jymingce from './com/sheet_jymingce.vue'
export default {
	components: {
		diaochaChart, diaochaFenlei, jymingce


	},
	data() {
		return {
			dialogShow: false,
			dialogshow1: false,
			activeName: "first",
			schoolId: 0,
			tjData: null,
			SchoolList: []
		}
	},
	mounted() {
		this.getSchoolList()
		//this.getDataList()

	},
	methods: {
		getSchoolList() {
			this.$http.post("/api/school_list").then(res => {
				let SchoolList = res.data
				this.$http.post("/api/get_jiuye_tjschools").then(res2 => {
					for (let item of res2.data) {
						for (let s of SchoolList) {
							if (s.id == item.pmid) {
								s.hasData = true
							}
						}
					}
					this.SchoolList = SchoolList
					for (let item of SchoolList) {
						if (item.hasData) {
							this.setSchool(item.id)
							break
						}
					}
				})
			})
		},
		setSchool(id) {
			this.schoolId = 0;
			this.tjData = null;
			setTimeout(() => {
				this.schoolId = id
				this.getDataList()
			}, 200)

		},
		handleClick() {
			if (this.activeName == "second") {
				this.getDataList()

			}
		},
		getDataList() {

			this.$http.post("/api/get_jiuye_tjtable", { tpmid: this.schoolId }).then(res => {
				if (!res.data) {
					this.$message.error("还没有上传统计表")
				} else {
					this.tjData = res.data
					this.$nextTick(e => {
						$("table", "#tableContent").addClass("dttable")
					})
				}



			})


		},
		handlePageChange(page) {
			this.page.current_page = page;
			this.getDataList();
		},
		viewInfo(e) {
			// e.keyword = this.searchForm.keyword
			// e.xibu_name = this.searchForm.xibu_name
			// e.class_name = this.searchForm.class_name
			this.xunfang = e

			this.dialogShow = true
		},
		uploadFiles(e) {
			if (e.src.indexOf("doc") > 0) {

				this.$http.post("/api/import_jiuyetj_word", {
					url: e.src
				}).then(res => {


					this.$message.success("导入成功")
					if (res.data.msg && res.data.msg.length > 0) {
						let html = ''
						for (let msg of res.data.msg) {
							html += "<div style='font-size:12px'>" + msg.msg + "</div>"
						}
						this.$alert(html, '未导入数据', {
							dangerouslyUseHTMLString: true
						});
					}
					this.dialogshow1 = false
					this.activeName = "second"
					this.getDataList()
					setTimeout(() => {
						this.handleClick()
					}, 500);



				})

			} else {
				this.$http.post("/api/import_jiuyefl_xls", {
					url: e.src
				}).then(res => {

					this.getDataList()
					this.$message.success("导入成功")
					if (res.data.msg.length > 0) {
						let html = ''
						for (let msg of res.data.msg) {
							html += "<div style='font-size:12px'>" + msg['巡访学生'] + ":" + msg.msg + "</div>"
						}
						this.$alert(html, '未导入数据', {
							dangerouslyUseHTMLString: true
						});
					}
					this.dialogShow = false
					this.activeName = "none"
					this.$nextTick(e => {
						this.activeName = "third"
					})


				})
			}
		},
		donwAll() {
			this.$http.post("/api/all_files_down", {
				school_id: this.schoolId
			}).then(res => {
				if (res.data.file) {
					
					window.open(res.data.file, "_blank")
				} else {
					this.$message({
						type: 'error',
						message: '该学校未上传统计表!'
					});
				}
			})

		}

	}
}
</script>

<style scoped>
.school {
	line-height: 30px;
	border-radius: 4px;
	cursor: pointer;
	padding: 5px 10px;
}

.school:hover {
	background-color: aliceblue;

}

.ckd {
	background-color: #547df2;
	color: #fff;
}

.ckd:hover {
	background-color: #547df2;
	color: #fff;
}
</style>